<template>
    <div class="page">
        <el-row class="query-form p_b1">
            <el-col :span="16">
                <el-input size="small" v-model="searchForm.numberOfPeople" placeholder="请输入人数" maxlength="50" class="m_r1"
                          style="width: 30%" clearable></el-input>
                <el-button type="primary" @click="queryData(1)" size="small" icon="el-icon-search">查询
                </el-button>
                <el-button @click="resetSearch()" size="small" icon="el-icon-refresh-right">重置</el-button>
            </el-col>
            <el-col :span="8" class="text_right">
                <el-button type="primary" v-if="hasPermission('service:numberManagement:delete')"
                           :disabled="dataListSelections.length <= 0" @click="del()" size="small">删除
                </el-button>
                <el-button type="primary" v-if="hasPermission('service:numberManagement:add')"
                           @click="addEdit(null, 0)" size="small">
                    新增
                </el-button>
            </el-col>
        </el-row>
        <div class="bg-white">
            <el-table :data="list"
                      v-loading="loading"
                      @selection-change="selectionChangeHandle"
                      size="small"
                      height="calc(100vh - 280px)" class="table">
                <el-table-column type="selection" :selectable="checkSelectable" width="50" fixed></el-table-column>
                <el-table-column prop="numberOfPeople" label="人数" show-overflow-tooltip></el-table-column>
                <el-table-column prop="state" label="状态" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <el-switch v-model="scope.row.state"
                                   @change="statusChange(scope.row)"
                                   :active-value="'0'"
                                   :inactive-value="'1'"
                        ></el-switch>
                    </template>
                </el-table-column>
                <el-table-column prop="createUser" label="添加人" show-overflow-tooltip></el-table-column>
                <el-table-column prop="createTime" label="添加时间" show-overflow-tooltip></el-table-column>
                <el-table-column prop="updateTime" label="最后修改时间" show-overflow-tooltip></el-table-column>
                <el-table-column fixed="right" width="150" label="操作">
                    <template slot-scope="scope">
                        <el-button v-if="hasPermission('service:numberManagement:delete')" :disabled="scope.row.state == '0'" type="text" size="mini"
                                   @click="del([scope.row.id])">删除
                        </el-button>
                        <el-button v-if="hasPermission('service:numberManagement:edit')" type="text" size="mini"
                                   @click="addEdit(scope.row, 1)">编辑
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination
                    @size-change="sizeChangeHandle"
                    @current-change="currentChangeHandle"
                    :current-page="searchForm.current"
                    :page-sizes="[10, 20, 50, 100]"
                    :page-size="searchForm.size"
                    :total="total"
                    background
                    layout="total, sizes, prev, pager, next, jumper">
            </el-pagination>
        </div>
        <!--新增、编辑-->
        <el-dialog :title="method == 0 ? '新增人数':'编辑人数'" width="40%" :close-on-click-modal="false" :visible.sync="visible">
            <el-form size="small" :model="addEditForm" ref="addEditForm" label-width="140px">
                <el-form-item label="人数：" prop="numberOfPeople"
                              :rules="[{ required: true, message: '请输入人数', trigger: 'blur' }]">
                    <el-input size="small" v-model.trim="addEditForm.numberOfPeople" maxlength="20"
                              placeholder="请输入人数（限20字）" clearable></el-input>
                </el-form-item>
                <el-form-item label="状态：" prop="state">
                    <el-switch v-model="addEditForm.state"
                               :active-value="'0'"
                               :inactive-value="'1'"
                    ></el-switch>
                </el-form-item>
                <div v-if="method == 1">
                    <el-form-item label="添加人：">{{editInfo.createUser}}</el-form-item>
                    <el-form-item label="添加时间：">{{editInfo.createTime}}</el-form-item>
                    <el-form-item label="最后修改时间：">{{editInfo.updateTime}}</el-form-item>
                </div>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button size="small" @click="visible = false">关闭</el-button>
                <el-button size="small" type="primary" @click="submit()" v-noMoreClick>提交</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                searchForm: {
                    numberOfPeople: '',
                    current: 1,
                    size: 10,
                },
                list: [],
                total: 0,
                loading: false,
                dataListSelections: [],
                // 新增、编辑
                visible: false,
                addEditForm: {
                    numberOfPeople: '',
                    state: '',
                },
                method: 0,
                editInfo: {},
            }
        },
        mounted() {
            this.queryData(1)
        },
        methods: {
            // 获取数据
            queryData(type) {
                if (type == 1) {
                    this.searchForm.current = 1
                }
                this.loading = true
                this.$axios(this.api.ticketing.sysGetTicketingNumberOfPeople, this.searchForm).then((res) => {
                    if (res.status) {
                        this.list = res.data.records
                        if (this.list.length == 0 && this.searchForm.current > 1) {
                            this.searchForm.current--
                            this.queryData()
                        }
                        this.total = parseInt(res.data.total)
                    } else {
                        this.$message.error('查询失败');
                    }
                    this.loading = false
                })
            },
            checkSelectable(row) {
                return row.state == '1'
            },
            // 重置
            resetSearch() {
                this.searchForm = {
                    numberOfPeople: '',
                    current: 1,
                    size: 10,
                }
                this.queryData(1)
            },
            // 状态修改
            statusChange(row) {
                this.$axios(this.api.ticketing.updateTicketingnumberofpeopleById, row, 'put').then((res) => {
                    if (res.status) {
                        this.$message.success('操作成功')
                        this.visible = false
                        this.queryData();
                    } else {
                        this.$message.error(res.msg)
                        this.queryData();
                    }
                })
            },
            // 新增、编辑 row:数据；method：0新增，1编辑
            addEdit(row, method) {
                this.visible = true
                this.method = method
                this.$nextTick(() => {
                    this.$refs.addEditForm.resetFields();
                    if (method == 1) {
                        this.recover(this.addEditForm, row);
                        this.editInfo = row
                    }
                })
            },
            // 提交
            submit() {
                this.$refs['addEditForm'].validate((valid) => {
                    if (valid) {
                        if(this.method == '0') {  // 新增
                            this.$axios(this.api.ticketing.saveTicketingnumberofpeople, this.addEditForm, 'post').then(res => {
                                if (res.status) {
                                    this.$message.success(res.msg)
                                    this.visible = false
                                    this.queryData(1)
                                } else {
                                    this.$message.error(res.msg)
                                }
                            })
                        } else { // 编辑
                            this.statusChange({id: this.editInfo.id, ...this.addEditForm})
                        }
                    }
                })
            },
            // 删除
            del(id) {
                let ids = id || this.dataListSelections.map(item => {
                    return item.id
                })
                this.$confirm(`确定删除这条数据吗?`, "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                }).then(() => {
                    this.$axios(this.api.ticketing.batchRemoveTicketingNumberOfPeople, ids, 'post').then((res) => {
                        if (res.status) {
                            this.$message.success(res.msg)
                            this.queryData();
                        } else {
                            this.$message.error(res.msg)
                        }
                    })
                });
            },
            // 每页数
            sizeChangeHandle(val) {
                this.searchForm.size = val
                this.searchForm.current = 1
                this.queryData()
            },
            // 当前页
            currentChangeHandle(val) {
                this.searchForm.current = val
                this.queryData()
            },
            // 多选
            selectionChangeHandle(val) {
                this.dataListSelections = val
            },
        }
    }
</script>

<style scoped>

</style>
